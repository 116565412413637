import { NgModule } from '@angular/core';

import { Routes, RouterModule, Route } from '@angular/router';
import {
  ActivateComponent,
  AuthGuard,
  LayoutComponent,
  LoginComponent,
  LogoutComponent,
  RequestPasswordResetComponent,
  ResetPasswordComponent,
} from '@groceriya/auth/feature';

const authRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'activate',
        component: ActivateComponent,
      },
      {
        path: 'request-password-reset',
        component: RequestPasswordResetComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
    ],
  },
];

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    children: authRoutes,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
