import { ApplicationErrorConfigs } from '@groceriya/utils';

const configs: ApplicationErrorConfigs = {
  // generic
  'generic:malformat_request': {
    title: 'Something went wrong',
    message: 'Refresh your browser page and try again.',
  },
  'generic:internal_server_error': {
    title: 'Error occurred',
    message: 'Please contact your system administrator.',
  },
  // auth
  'auth:user_not_found': {
    title: 'User not found',
    message:
      'Your are not registered in the system. Please contact your system administrator.',
  },
  'auth:non_active_user': {
    title: 'User not activated',
    message:
      'Your user account is not activated. Please contact your system administrator.',
  },
  'auth:login_faiiled': {
    title: 'User login failed',
    message:
      'Your login attempt is failed. Retry with correct email and password.',
  },
  'auth:suspended_user': {
    title: 'User suspended',
    message:
      'Your user account is suspended. Please contact your system administrator.',
  },
  'auth:login_limit_exceed': {
    title: 'Logging attempt exceeded',
    message:
      'Your login attempt exceeded. Please contact your system administrator.',
  },
};

export default configs;
